@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./styles/fonts.css');

#passwordContianer > div > div > input {
  width: 100%;
  padding: 0.5rem;
}

#password {
  padding: 0px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.p-fileupload-choose {
  background: #205255;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #189bcc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #46afd6;
}

/* Optional Scrollbar Styling (limited support) */
.horizontal-scroll-container::-webkit-scrollbar {
  width: 8px; /* Set width of the scrollbar */
  height: 5px; /* Set height of the horizontal scrollbar */
}

.horizontal-scroll-container::-webkit-scrollbar-thumb {
  background: #189bcc;
  border-radius: 10px;
}

.horizontal-scroll-container::-webkit-scrollbar-thumb:hover {
  background: #46afd6;
}

.loginMainBg {
  background: -webkit-linear-gradient(
    left,
    rgb(15, 32, 62) 0%,
    rgb(65, 78, 102) 97%,
    rgb(66, 80, 103) 100%
  );
  background: -o-linear-gradient(
    left,
    rgb(15, 32, 62) 0%,
    rgb(65, 78, 102) 97%,
    rgb(66, 80, 103) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgb(15, 32, 62) 0%,
    rgb(65, 78, 102) 97%,
    rgb(66, 80, 103) 100%
  );
  background: -moz-linear-gradient(
    left,
    rgb(15, 32, 62) 0%,
    rgb(65, 78, 102) 97%,
    rgb(66, 80, 103) 100%
  );
  background: linear-gradient(
    to right,
    rgb(15, 32, 62) 0%,
    rgb(65, 78, 102) 97%,
    rgb(66, 80, 103) 100%
  );
}

ol,
menu {
  list-style: decimal;
  padding-left: 26px;
  margin-right: 12px;
  margin-bottom: 20px;
}

ul {
  list-style: disc;
  padding-left: 26px;
  margin-right: 12px;
  margin-bottom: 20px;
}

ol li {
  margin: 8px 0;
  padding-left: 6px;
}

ul li {
  margin: 8px 0;
  padding-left: 6px;
}

svg {
  overflow: visible;
}
